<template>
  <div id="issue">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />发布
        </van-col>
        <van-col span="14" class="header-title" style="text-align: right">
          <div
            class="submit-btn"
            @click="post_notifications1"
            v-if="active == 1"
          >
            立即发布
          </div>
          <div
            class="submit-btn"
            @click="post_notifications"
            v-if="active == 0"
          >
            立即发布
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="issue-wrapper">
      <van-tabs color="#3A5BE9" type="card" v-model="active">
        <van-tab title="文章">
          <div class="issue-container">
            <div class="flex">
              <label>发布对象</label>
              <div class="flex-box">
                <van-checkbox-group v-model="show_scope" direction="horizontal">
                  <!-- <van-checkbox name="1">旅行社</van-checkbox> -->
                  <van-checkbox name="2">导游</van-checkbox>
                  <van-checkbox name="4">游客</van-checkbox>
                </van-checkbox-group>
              </div>
            </div>
            <div class="flex">
              <label>标题</label>
              <div class="flex-box">
                <input
                  type="text"
                  v-model="notifications.title"
                  placeholder="请输入标题"
                />
              </div>
            </div>
            <quillEditor
              v-model="notifications.content"
              ref="myQuillEditor"
              :options="editorOption"
            >
            </quillEditor>
            <van-uploader
              max-count="1"
              id="quill-upload"
              style="visibility: hidden"
              
              accept="image/*,.mp4"
              :after-read="fnUploadRequestInEditor"
            />
            <div class="btn"  @click="post_notifications">立即发布</div>
          </div>
        </van-tab>
        <van-tab title="文件">
          <div class="issue-container">
            <div class="flex">
              <label>发布对象</label>
              <div class="flex-box">
                <van-checkbox-group
                  v-model="show_scope1"
                  direction="horizontal"
                >
                  <!-- <van-checkbox name="1">旅行社</van-checkbox> -->
                  <van-checkbox name="2">导游</van-checkbox>
                  <van-checkbox name="4">游客</van-checkbox>
                </van-checkbox-group>
              </div>
            </div>
            <div class="flex">
              <label>标题</label>
              <div class="flex-box">
                <input
                  type="text"
                  v-model="notifications1.title"
                  placeholder="请输入标题"
                />
              </div>
            </div>
            <div class="flex">
              <van-uploader
                max-count="1"
                v-model="fileList"
                
                accept="image/*,.pdf,xlsx,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :after-read="fnUploadRequest"
              />
            </div>
            <div class="btn"  @click="post_notifications1">立即发布</div>
          </div>
        </van-tab>
      </van-tabs>
      <!-- <div class="submit-container">
        <div class="submit-btn" @click="post_notifications" v-if="active == 0">
          立即发布
        </div>
        <div class="submit-btn" @click="post_notifications1" v-if="active == 1">
          立即发布
        </div>
      </div> -->
      
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import oss from "@/utils/oss";
import { Toast } from "vant";
const toolbarOptions = [
  // ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["bold", "italic", "underline"],
  // ["blockquote", "code-block"], // 引用  代码块
  // [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  // [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ size: ["small", false, "large", "huge"] }], // 字体大小
  // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  // [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  // [{ font: [] }], // 字体种类
  // [{ align: [] }], // 对齐方式
  // ["clean"], // 清除文本格式
  ["link", "image", "video"], // 链接、图片、视频
];
export default {
  name: "issue",
  data() {
    const _self = this;
    return {
      editorHtml: "",
      editorOption: {
        placeholder: "在这儿写文章～",
        theme: "snow",
        modules: {
          // imageResize: {
          //   displayStyles: {
          //     backgroundColor: "black",
          //     border: "none",
          //     color: "white"
          //   },
          //   modules: ["Resize", "DisplaySize", "Toolbar"]
          // },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  _self.type = "image";
                  document.querySelector("#quill-upload").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  _self.type = "video";
                  document.querySelector("#quill-upload").click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      active: 0,
      result: [],
      show_scope: [],
      show_scope1: [],
      notifications: {
        notification_type: 1,
        show_scope: 0,
        title: "",
        content: "",
      },
      notifications1: {
        notification_type: 2,
        show_scope: 0,
        title: "",
        file_url: "",
      },
      fileList: [],
      type: "image",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push("/issue/list");
    },
    async post_notifications() {
      const _self = this;
      var show_scope = 0;
      _self.show_scope.forEach((item) => {
        show_scope = show_scope + Number(item);
      });
      if (show_scope == 0) {
        Toast("请选择发布对象");
        return false;
      }
      if (_self.notifications.content == "") {
        Toast("请填写内容");
        return false;
      }
      if (_self.notifications.title == "") {
        Toast("请填写标题");
        return false;
      }
      _self.notifications.show_scope = show_scope;
      const notifications = { notification: _self.notifications };
      const res = await this.$apis.post_notifications(notifications);
      if (res.status == 200) {
        Toast("发布成功!");
        _self.$router.go(-1);
      } else {
        Toast(res.message);
      }
    },
    async post_notifications1() {
      const _self = this;
      var show_scope = 0;

      _self.show_scope1.forEach((item) => {
        show_scope = show_scope + Number(item);
      });

      if (show_scope == 0) {
        Toast("请选择发布对象");
        return false;
      }
      if (_self.fileList.length == 0) {
        Toast("请上传文件");
        return false;
      }
      if (_self.notifications1.title == "") {
        Toast("请填写标题");
        return false;
      }
      _self.notifications1.show_scope = show_scope;
      _self.notifications1.file_url = _self.fileList[0].url;
      // return false
      const notifications = { notification: _self.notifications1 };

      const res = await this.$apis.post_notifications(notifications);
      if (res.status == 200) {
        Toast("发布成功!");
        _self.$router.go(-1);
      } else {
        Toast(res.message);
      }
    },
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        Toast.clear();
        _self.fileList = [{ url: res.fileUrl[0].split("?")[0] }];
      });
    },
    fnUploadRequestInEditor(option) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        const _self = this;
        let quill = this.$refs.myQuillEditor.quill;
        let url = res.fileUrl[0].split("?")[0];
        if (url) {
          let length = quill.getSelection().index;
          quill.insertEmbed(length, _self.type, url);
          quill.setSelection(length + 1);
        }
        Toast.clear();
      });
    },
  },
  components: {
    quillEditor,
  },
};
</script>

<style scoped>
#issue {
  height: 100vh;
  background: #f3f3f3;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-wrapper {
  height: 88vh;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding-top: 0.3rem;
  width: 95%;
  margin: 0 auto;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.issue-container {
  background: #fff;
  margin-top: 0.2rem;
  padding: 0.2rem;
}
.quill-editor {
  height: 40vh !important;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px;
  position: relative;
}
.flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
}
.flex label {
  color: #000000;
  font-size: 0.3rem;
  width: 80px;
}

.flex-box input {
  background: none;
  border: none;
  padding: 0.1rem 0;
  height: 1rem;
  line-height: 1rem;
  width: 100%;
}
.submit-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.4rem;
  background-color: #fff;
  line-height: 1.4rem;
  text-align: center;
}
.submit-btn {
  text-align: center;
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  float: right;
}
.btn{
  height: .8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: .8rem;
  /* margin-top:.4rem; */
  font-size: .3rem;
  border-radius: 5px;
}
</style>
